import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
          <p>
            CborgDB, The database is still under development !<br/>
            The database is developed in C language, the project is open source on GitHub.<br/><br/>
            GitHub repositories:<br/>
            - 📚 <a className="App-link" href="https://github.com/cborgdb/libcborg">libcborg</a>: libcborg is maintained by <a className="App-link" href="https://github.com/abenhlal">Adil Benhlal</a> for <a className="App-link" href="https://github.com/cborgdb/cborg">cborg</a> and its tools. <br/>
            - 🦾 <a className="App-link" href="https://github.com/cborgdb/cborg">cborg</a>: The program that will run the database.<br />
          </p>
          <p><a className="App-link" href="https://discord.gg/WDxhUD92bf">Join us on Discord !</a></p>
          © (copyright) 2021 CborgDB, (Adil Benhlal)
      </header>
    </div>
  );
}

export default App;
